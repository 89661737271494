import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { mapEdgesToNodes } from "../../lib/helpers";
import stars from "../../images/Reviews/five-stars.svg";

const ReviewCards = () => {
  const data = useStaticQuery(graphql`
    {
      yelp: file(relativePath: { eq: "Reviews/Yelp-alt.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 74)
        }
      }
      google: file(
        relativePath: { eq: "0.0 Repeating Modules/Testimonials/Google.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 61)
        }
      }
      facebook: file(
        relativePath: { eq: "0.0 Repeating Modules/Testimonials/Facebook.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 123)
        }
      }
      martindale: file(
        relativePath: {
          eq: "0.0 Repeating Modules/Testimonials/Martindale.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 123)
        }
      }
      verified: file(relativePath: { eq: "Reviews/Verified.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 124)
        }
      }
      defaultProfilePicture: file(
        relativePath: { eq: "Reviews/default-profile-picture.svg" }
      ) {
        publicURL
      }
      reviews: allGoogleReviewsSheet(sort: { fields: date, order: DESC }) {
        edges {
          node {
            review
            platform
          }
        }
      }
    }
  `);

  const reviewNodes = (data || {}).reviews ? mapEdgesToNodes(data.reviews) : [];

  return (
    <>
      {reviewNodes.map((review, i) => {
        return (
          <div
            className="mb-8 w-full break-inside-avoid rounded-lg bg-[#063E5C] p-3 text-center md:mb-10"
            key={i}
          >
            <div className="rounded-lg border border-gray-200 px-6 py-5 md:px-10 md:py-8">
              {/* <div className="mb-4 flex items-center justify-between">
                <div className="flex items-center space-x-3"> */}
              {/* {review.profilePicture && review.profilePicture.asset ? (
                    <div className="mb-2 inline-flex justify-center rounded-full">
                      <GatsbyImage
                        image={review.profilePicture.asset.gatsbyImageData}
                        alt={`${review.name} profile headshot`}
                        loading="lazy"
                        className="mx-auto"
                        width={40}
                        height={40}
                        className="z-0 rounded-full"
                      />
                    </div>
                  ) : (
                    <img
                      src={data.defaultProfilePicture.publicURL}
                      width="40"
                      alt="Default profile headshot"
                    />
                  )} */}
              {/* </div>
              </div> */}

              <img src={stars} width={119} className="mx-auto mb-4" />

              <blockquote>
                <q className="mb-4 block text-gray-50 before:hidden">
                  {review.review}
                </q>
              </blockquote>

              <div className="mb-4 block text-sm text-gray-50">
                {/* {review.name} */}
              </div>

              {review.platform === "Google" && (
                <GatsbyImage
                  image={data.google.childImageSharp.gatsbyImageData}
                  loading="lazy"
                  className="mx-auto"
                />
              )}

              {review.platform === "Yelp" && (
                <GatsbyImage
                  image={data.yelp.childImageSharp.gatsbyImageData}
                  loading="lazy"
                  className="mx-auto"
                />
              )}

              {review.platform === "Facebook" && (
                <GatsbyImage
                  image={data.facebook.childImageSharp.gatsbyImageData}
                  loading="lazy"
                  className="mx-auto"
                />
              )}

              {review.platform === "Martindale" && (
                <GatsbyImage
                  image={data.martindale.childImageSharp.gatsbyImageData}
                  loading="lazy"
                  className="mx-auto"
                />
              )}

              {review.platform === "Verified" && (
                <GatsbyImage
                  image={data.verified.childImageSharp.gatsbyImageData}
                  loading="lazy"
                  className="mx-auto"
                />
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ReviewCards;

import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";
import ReviewCards from "../components/Reviews/ReviewCards";
import CallToAction from "../components/Repeating/CTA";
import { StaticImage } from "gatsby-plugin-image";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Reviews | Brierton, Jones & Jones | San Diego Attorneys"
        description="Brierton, Jones & Jones, LLP are a top San Diego estate planning law firm. Read our reviews to learn more about the exceptional service we provide."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="bg-gray-50 pt-10 pb-7 md:py-4">
        <div className="relative">
          <div className="container">
            <div className="grid items-center gap-y-14 md:min-h-[506px] md:grid-cols-2">
              <div className="md:pr-6 lg:pr-12">
                <h1>Client Testimonials</h1>
                <p>
                  We take great pride in serving our community and welcome your
                  feedback. Here’s what our clients say about working with
                  Brierton, Jones & Jones, LLP.
                </p>
                <ButtonSolid href="/review-us/" text="Leave a Review" />
              </div>

              <div className="-mr-4 md:absolute md:right-0 md:top-0 md:mr-0 md:h-full md:w-[50vw] md:pl-6 lg:pl-12">
                <StaticImage
                  src="../images/16.0 Reviews/1.0 Hero desktop.jpg"
                  loading="eager"
                  className="h-full rounded-l-[90px]"
                  imgClassName="object-left"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-blue pt-7 pb-20 md:pt-20 md:pb-24">
        <div className="container">
          <div className="md:columns-2 md:gap-x-12">
            <ReviewCards />
          </div>
        </div>
      </section>

      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Global/Open Graphs/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Global/Open Graphs/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
